import Layout from '../../components/layout/LayoutComponentEn'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const IndexPage = () => {

  const dataQuery = useStaticQuery(graphql`
      query {
        allFile(
          filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "logos"}}
          sort: {fields: name}
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
  
    `)

    const imagesData = dataQuery.allFile.nodes.map((node) => {
        return {
            image: getImage(node),
            title: node.name
        }
    });

  return (
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            <div className='flex flex-col 2xl:mx-64 sm:mx-72 mx-0 mt-20 bg-white/0 flex-wrap'>
                <h2 className='text-center text-3xl font-bold'>BRANDS WE DISTRIBUTE</h2>
            </div>  
          <div className='flex flex-col xl:flex-row 2xl:mx-64 lg:mx-32 justify-center mx-0 my-20 bg-white/0 flex-wrap'>
            
            {
                imagesData.map((m, index) => {
                    if(index % 2 === 0){
                        return(
                            <MarcasRight key={index} marca={m}></MarcasRight>
                        )
                    }
                    else{
                        return(
                            <MarcasLeft key={index} marca={m}></MarcasLeft>
                        )
                    }
                    
                })
            }
            
          </div>
            
        </div>
        
      </>
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage

const MarcasLeft = ({marca}) => {
    return (
        <div className='basis-1 lg:basis-1/2 w-full flex flex-col z-20 relative move-left my-5 p-5'>
            <Marcas title={marca.title} image={marca.image}/>
        </div>
    )
}

const MarcasRight = ({marca}) => {
    return (
        <div className='basis-1 md:basis-1/2 w-full flex flex-col z-20 relative move-right my-5 p-5'>
            <Marcas title={marca.title} image={marca.image}/>
        </div>
    )
}

const Marcas = ({image, title}) => {
    return (
        <div className='flex flex-col p-5 items-center shadow-xl bg-secondary/50 rounded-2xl'>
            <GatsbyImage image={image} alt='title' />
            <p className='text-center text-lg'>{title}</p>        
        </div>
    )
}

export const Head = () => (
  <>
    <title>Sobre Nosotros | NecarMed</title>
  </>
)